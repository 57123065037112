console.log("import", "init_nodefer_sub1.js");
import "./init_nodefer_sub1.js";
console.log("import", "init_nodefer_sub1.js", "done");

console.log("import", "init_nodefer_sub2.js");
import "./init_nodefer_sub2.js";
console.log("import", "init_nodefer_sub2.js", "done");

console.log("import", "init_nodefer_sub3.js");
import "./init_nodefer_sub3.js";
console.log("import", "init_nodefer_sub3.js", "done");
