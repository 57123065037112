console.log("import", "plugins.js");
import "./app-assets/js/plugins.js";

console.log("import", "custom-script.js");
import "./app-assets/js/custom/custom-script.js";

// console.log("import", "dragdroptouch.js");
// import "./app-assets/js/custom/dragdroptouch.js";

// console.log("import", "fabbi_organizer.js");
// import "./app-assets/js/custom/fabbi_organizer.js";

// console.log("import", "fabbi_carousel.js");
// import "./app-assets/js/custom/fabbi_carousel.js";

// console.log("import", "fabbi_time_left_display.js");
// import "./app-assets/js/custom/fabbi_time_left_display.js";

// console.log("import", "fabbi_info_line.js");
// import "./app-assets/js/custom/fabbi_info_line.js";
